import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import WarningRounded from '@mui/icons-material/WarningRounded';
import { useQuery } from '@apollo/client';
import MASTER_ENTITY_ALERTS from '../../../../graphql/risk_evaluation_model.py/masterEntityAlerts';

const BaseInfoNotFound = ({ message }) => (
  <Typography color="error" justifyContent="center">
    <Stack alignItems="center" direction="row" fontWeight="bold">
      <WarningRounded />
      {message}
    </Stack>
  </Typography>
);

const MissingInfoAlerts = ({ masterEntityId }) => {
  const { data: masterEntity, loading } = useQuery(
    MASTER_ENTITY_ALERTS,
    { variables: { masterEntityId },
      skip: !masterEntityId,
    },
  );
  const tgrJson = useMemo(
    () => masterEntity?.getMasterEntity.company.currentTgr?.tgrInfoDict,
    [masterEntity],
  );

  const currentDicom = useMemo(
    () => masterEntity?.getMasterEntity?.currentDicom
     || masterEntity?.getMasterEntity?.currentDicomPlatinum,
    [masterEntity],
  );

  const DicomInfoNotFound = () => {
    if (currentDicom && !currentDicom.completedAt) {
      return <BaseInfoNotFound message="Dicom no se pudo procesar" />;
    }
    return <></>;
  };

  const CollectionInfoNotFound = () => {
    if (tgrJson?.collection?.length) {
      return <BaseInfoNotFound message="Cliente: Cobranzas Judiciales, no encontró información" />;
    }
    return <></>;
  };

  if (loading || tgrJson?.collection?.length) {
    return <></>;
  }
  return (
    <>
      <DicomInfoNotFound />
      <CollectionInfoNotFound />
    </>

  );
};

MissingInfoAlerts.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
};

export default MissingInfoAlerts;

BaseInfoNotFound.propTypes = {
  message: PropTypes.string.isRequired,
};
